<template>
  <b-col sm="12" md="12" lg="12" class="px-xl-2 mx-auto">
    <img src="../../assets/images/logo/logo-white.png" />
  </b-col>
</template>
<script>
import {
  BCol,
} from "bootstrap-vue";

export default {
  components: {
    BCol,
  },
}
</script>
<style scoped>
img{
  max-width: 150px;
}
</style>