<template>
    <auth-layout>
      <!-- Login-->
      <b-col lg="5" class="d-flex align-items-center auth-bg px-2 p-lg-5">
        <b-col sm="8" md="6" lg="12" class="px-xl-2 mx-auto">
          <b-card-title class="mb-1 font-weight-bold" title-tag="h2">
            Welcome to PepeaSMS Admin Panel
          </b-card-title>
          <b-card-text class="mb-2">
            Please sign-in to your account and manage the PepeaSMS platfrom from here.
          </b-card-text>

          <!-- form -->
          <validation-observer ref="loginForm" #default="{ invalid }">
            <b-form class="auth-login-form mt-2" @submit.prevent>
              <!-- email -->
              <b-form-group label="Email" label-for="userEmail">
                <validation-provider
                  #default="{ errors }"
                  name="Email"
                  rules="required|email"
                >
                  <b-form-input
                    id="userEmail"
                    v-model="userEmail"
                    :state="errors.length > 0 ? false : null"
                    name="userEmail"
                    placeholder="john@example.com"
                  />
                  <small class="text-danger">{{ errors[0] }}</small>
                  <small class="text-danger" v-if="resError">{{ resError }}</small>

                </validation-provider>
              </b-form-group>

              <!-- forgot password -->
              <b-form-group>
                <div class="d-flex justify-content-between">
                  <label for="password">Password</label>
                  <b-link :to="{ name: 'forgot-password' }">
                    <small>Forgot Password?</small>
                  </b-link>
                </div>
                <validation-provider
                  #default="{ errors }"
                  name="Password"
                  rules="required"
                >
                  <b-input-group
                    class="input-group-merge"
                    :class="errors.length > 0 ? 'is-invalid' : null"
                  >
                    <b-form-input
                      id="password"
                      v-model="password"
                      :state="errors.length > 0 ? false : null"
                      class="form-control-merge"
                      :type="passwordFieldType"
                      name="password"
                      placeholder="············"
                    />
                    <b-input-group-append is-text>
                      <feather-icon
                        class="cursor-pointer"
                        :icon="passwordToggleIcon"
                        @click="togglePasswordVisibility"
                      />
                    </b-input-group-append>
                  </b-input-group>
                  <small class="text-danger">{{ errors[0] }}</small>
                </validation-provider>
              </b-form-group>

              <!-- checkbox -->
              <b-form-group>
                <b-form-checkbox
                  id="remember-me"
                  v-model="status"
                  name="checkbox-1"
                >
                  Remember Me
                </b-form-checkbox>
              </b-form-group>

              <!-- submit buttons -->
              <b-button
                type="submit"
                variant="primary"
                class="d-flex align-items-center justify-content-center"
                block
                @click="login"
                :disabled="invalid || formIsLoading"
                :class="{ disabled: invalid }"
              >
                <b-spinner small v-if="formIsLoading" />
                &nbsp;
                Sign in
              </b-button>
            </b-form>
          </validation-observer>
        </b-col>
      </b-col>
      <!-- /Login-->
   </auth-layout>
</template>

<script>
/* eslint-disable global-require */
import { ValidationProvider, ValidationObserver } from "vee-validate";
import AuthLayout from "@/layouts/auth/AuthLayout.vue";
import {
  BCol,
  BLink,
  BFormGroup,
  BFormInput,
  BInputGroupAppend,
  BInputGroup,
  BFormCheckbox,
  BCardText,
  BCardTitle,
  BImg,
  BForm,
  BSpinner,
  BButton,
} from "bootstrap-vue";
import { required, email } from "@validations";
import { togglePasswordVisibility } from "@core/mixins/ui/forms";
import useJwt from "@/auth/jwt/useJwt";
import { getHomeRouteForLoggedInUser } from "@/auth/utils";
import ToastificationContent from "@core/components/toastification/ToastificationContent.vue";

export default {
  components: {
    AuthLayout,
    BCol,
    BLink,
    BFormGroup,
    BFormInput,
    BInputGroupAppend,
    BInputGroup,
    BFormCheckbox,
    BCardText,
    BCardTitle,
    BImg,
    BForm,
    BSpinner,
    BButton,
    ValidationProvider,
    ValidationObserver,
  },
  mixins: [togglePasswordVisibility],
  data() {
    return {
      status: "",
      password: "",
      resError:null,
      formIsLoading:false,
      userEmail: "",
      // validation rulesimport store from '@/store/index'
      required,
      email,
    };
  },
  computed: {
    passwordToggleIcon() {
      return this.passwordFieldType === "password" ? "EyeIcon" : "EyeOffIcon";
    },
  },
  methods: {
    login() {
      this.resError = null;
      this.formIsLoading = true;
      this.$refs.loginForm.validate().then((success) => {
        if (success) {
          useJwt
            .login({
              email: this.userEmail,
              password: this.password,
            })
            .then((response) => {
              const { user } = response.data;
              useJwt.setToken(response.data.token);
              localStorage.setItem("userData", JSON.stringify(user));
              localStorage.setItem("smsUnits", user.sms_units);
              this.formIsLoading = false;
              this.$router
                .replace(getHomeRouteForLoggedInUser("dummy-role"))
                .then(() => {
                  this.$toast({
                    component: ToastificationContent,
                    position: "top-right",
                    props: {
                      title: `Welcome ${ user.first_name + " " + user.last_name }`,
                      icon: "LogInIcon",
                      variant: "success",
                      text: 'You have successfully logged in. Now you can start to explore!',
                    },
                  });
                });
            })
            .catch((error) => {
              for (let err of error.response.data.error) {
                if (err.substr(0, 10) == "unverified") {
                  let userId = err.substring(10);
                  this.$http.post('/send-verification-code',{
                    id:userId,
                  })
                  .then(response => {
                    this.formIsLoading = false;
                    this.$router
                    .push({ 
                      name: 'verify-account-codes',
                      params: { id: userId }
                    })
                    .then(() => {
                      this.$toast({
                        component: ToastificationContent,
                        position: "top-right",
                        props: {
                          title: `User Verification Needed`,
                          icon: "AlertCircleIcon",
                          variant: "warning",
                          text: response.data.message,
                        },
                      });
                    });
                  })
                  .catch(error => {
                    for (let err of error.response.data.error) {
                      this.resError = err;
                    }
                  })
                }else{
                  this.resError = err;
                }
              }
              this.formIsLoading = false;
            });
        }
      });
    },
  },
};
</script>
